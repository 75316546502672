<template>
	<en-table-layout
		pagination
		:tableData="pageData.data"
		:stripe="false"
		:loading="loading">
		<template slot="table-columns">
			<el-table-column prop="tag_name" label="标签名称"/>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" @click="addtag(scope.row)">标签商品设置</el-button>
				</template>
			</el-table-column>
		</template>
		<el-pagination
			slot="pagination"
			@size-change="handlePageSizeChange"
			@current-change="handlePageCurrentChange"
			:current-page="pageData.page_no"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
			background
			:page-size="pageData.page_size"
			:total="pageData.data_total">
		</el-pagination>
	</en-table-layout>
</template>

<script>
	import * as API_goodsTag from '@/api/goodsTag'
  import EnTableLayout from '../../../ui-components/TableLayout/src/main';
	export default {
		name: 'tagManage',
    components: {EnTableLayout},
    data() {
			return {
				/** 列表loading状态 */
				loading: false,

				/** 列表参数 */
				params: {
					page_no: 1,
					page_size: 20
				},

				/** 列表分页数据 */
				pageData: {data: []}
			}
		},
		mounted() {
			this.GET_TagsList()
		},
		methods: {

			/** 分页大小发生改变 */
			handlePageSizeChange(size) {
				this.params.page_size = size
				this.GET_TagsList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange(page) {
				this.params.page_no = page
				this.GET_TagsList()
			},

			/** 标签列表 */
			GET_TagsList() {
				this.loading = true
				API_goodsTag.getTagsList(this.params).then(response => {
					this.loading = false
					this.pageData = response;
				})
			},

			/** 标签商品设置 */
			addtag(row) {
				this.$router.push({ path: `/${this.$route.meta.shop ? 'shop-goods' : 'goods'}/tag-add/${row.tag_id}`, query: { tag_name: row.tag_name }})
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ div.toolbar {
		display: none;
	}
	/deep/ .el-table td:not(.is-left) {
		text-align: center;
	}

	.inner-toolbar {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.toolbar-search {
		margin-right: 10px;
	}

	.goods-image {
		width: 50px;
		height: 50px;
	}

</style>
